













.empty-seat {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -100px;
  span {
    text-align: center;
    color: #b8b8b8;
  }
  img {
    width: 10.9375rem;
  }
}
