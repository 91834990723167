







































.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5c6be8;
}
